exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-menu-tsx": () => import("./../../../src/pages/menu.tsx" /* webpackChunkName: "component---src-pages-menu-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-shopping-list-tsx": () => import("./../../../src/pages/shopping-list.tsx" /* webpackChunkName: "component---src-pages-shopping-list-tsx" */),
  "component---src-pages-tt-index-tsx": () => import("./../../../src/pages/tt/index.tsx" /* webpackChunkName: "component---src-pages-tt-index-tsx" */),
  "component---src-pages-tt-rr-tsx": () => import("./../../../src/pages/tt/rr.tsx" /* webpackChunkName: "component---src-pages-tt-rr-tsx" */),
  "component---src-pages-tt-rrs-tsx": () => import("./../../../src/pages/tt/rrs.tsx" /* webpackChunkName: "component---src-pages-tt-rrs-tsx" */),
  "component---src-pages-tt-tournaments-tsx": () => import("./../../../src/pages/tt/tournaments.tsx" /* webpackChunkName: "component---src-pages-tt-tournaments-tsx" */)
}

